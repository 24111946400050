<template>
    <div>
      <div class="mobile-categories first-row" v-if="isHome">
        <div class="col-3 col-cat4" style="display: none">
          <a
            class="navigation__item ps-toggle--sidebar"
            href="#navigation-mobile"
            style="position: relative"
          >
            <div class="category-item">
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(3.784,3.784) scale(0.956,0.956)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a31e"
                        stroke="#757575"
                        stroke-width="8"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M54.46667,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM54.46667,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM54.46667,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a31e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M40.13333,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM40.13333,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM40.13333,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">Categories</h6>
            </div>
          </a>
        </div>
  
        <!-- <div class="col-3 col-cat4">
          <router-link :to="{ name: 'shop-now' }">
            <div class="category-item">
              <h6 class="category-title">ShopAllNow</h6>
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="12"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M165.12,48.16c0,22.76313 -18.51687,41.28 -41.28,41.28c-5.09281,0 -10.06469,-0.92719 -14.82156,-2.76812l-73.54344,73.54344c-3.26531,3.26531 -7.55187,4.89125 -11.85187,4.89125c-4.28656,0 -8.57313,-1.62594 -11.83844,-4.89125c-3.15781,-3.17125 -4.90469,-7.36375 -4.90469,-11.83844c0,-4.48813 1.74688,-8.69406 4.90469,-11.85188l73.55688,-73.54344c-1.85438,-4.75688 -2.78156,-9.72875 -2.78156,-14.82156c0,-22.76312 18.51688,-41.28 41.28,-41.28c4.36719,0 8.7075,0.69875 12.92688,2.08281l5.29437,1.74687l-27.93656,27.95l5.4825,13.73313l13.71969,5.4825l27.95,-27.93656l1.74687,5.29438c1.3975,4.21937 2.09625,8.55969 2.09625,12.92687z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M163.02375,35.23313l-1.74687,-5.29438l-27.95,27.93656l-13.71969,-5.4825l-5.4825,-13.73313l27.93656,-27.95l-5.29437,-1.74687c-4.21938,-1.38406 -8.55969,-2.08281 -12.92688,-2.08281c-22.76312,0 -41.28,18.51688 -41.28,41.28c0,5.09281 0.92719,10.06469 2.78156,14.82156l-73.55688,73.54344c-3.15781,3.15781 -4.90469,7.36375 -4.90469,11.85188c0,4.47469 1.74688,8.66719 4.90469,11.83844c3.26531,3.26531 7.55188,4.89125 11.83844,4.89125c4.3,0 8.58656,-1.62594 11.85187,-4.89125l73.54344,-73.54344c4.75688,1.84094 9.72875,2.76812 14.82156,2.76812c22.76313,0 41.28,-18.51687 41.28,-41.28c0,-4.36719 -0.69875,-8.7075 -2.09625,-12.92687z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">HouseHold <br />Essentials@PH</h6>
            </div>
          </router-link>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <router-link
            :to="{
              name: 'category_products',
              params: { slug: 'building-materials-hardware' },
            }"
          >
            <div class="category-item">
              <h6 class="category-title">TilesToRoof <br />Depot</h6>
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="9"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M120.0042,20.64068c0.92166,2.17779 0.4118,4.69802 -1.28389,6.3463l-24.61302,24.61302l26.29271,26.29271l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667c0.92166,2.17779 0.4118,4.69801 -1.28389,6.3463l-24.61302,24.61302l18.87969,18.87969c1.49778,1.43802 2.10113,3.5734 1.57735,5.5826c-0.52378,2.0092 -2.09284,3.57826 -4.10204,4.10204c-2.0092,0.52378 -4.14458,-0.07957 -5.5826,-1.57735l-22.09349,-22.09349c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-32.74271,-32.74271c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-22.11589,-22.11589c-1.6719,-1.62719 -2.19121,-4.10513 -1.31331,-6.26668c0.8779,-2.16155 2.97794,-3.57567 5.31096,-3.57628c1.54801,0.00009 3.03022,0.62609 4.10964,1.73568l18.87969,18.87969l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667zM127.73464,116.93984l-23.72839,23.72839c-11.1972,11.1972 -29.35046,11.1972 -40.54766,0l-1.16458,-1.16458l-1.63489,1.6237c-5.46387,5.46387 -14.31165,5.46387 -19.77552,0l-0.95182,-0.95182l-12.94479,12.94479c-1.43802,1.49776 -3.57339,2.1011 -5.58258,1.57732c-2.00919,-0.52378 -3.57824,-2.09283 -4.10202,-4.10202c-0.52378,-2.00919 0.07955,-4.14456 1.57731,-5.58258l12.94479,-12.94479l-0.94062,-0.94062c-5.46387,-5.46387 -5.46387,-14.32285 0,-19.78672l1.6237,-1.6237l-1.17578,-1.17578c-11.1972,-11.1972 -11.1972,-29.35046 0,-40.54766l23.72838,-23.72839zM51.6,86c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333zM80.26667,114.66667c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M114.55469,17.14401c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-18.87969,-18.87969c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568c-2.33303,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l22.11589,22.11589c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l32.74271,32.74271c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l22.09349,22.09349c1.43802,1.49778 3.5734,2.10113 5.5826,1.57735c2.0092,-0.52378 3.57826,-2.09284 4.10204,-4.10204c0.52378,-2.0092 -0.07957,-4.14458 -1.57735,-5.5826l-18.87969,-18.87969l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08575,-3.56638 -5.44951,-3.49667c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-26.29271,-26.29271l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08576,-3.56638 -5.44951,-3.49667zM55.06016,44.26536l-23.72838,23.72839c-11.1972,11.1972 -11.1972,29.35046 0,40.54766l1.17578,1.17578l-1.6237,1.6237c-5.46387,5.46387 -5.46387,14.32285 0,19.78672l0.94062,0.94062l-12.94479,12.94479c-1.49776,1.43802 -2.1011,3.57339 -1.57731,5.58258c0.52378,2.00919 2.09283,3.57824 4.10202,4.10202c2.00919,0.52378 4.14456,-0.07955 5.58258,-1.57732l12.94479,-12.94479l0.95182,0.95182c5.46387,5.46387 14.31165,5.46387 19.77552,0l1.63489,-1.6237l1.16458,1.16458c11.1972,11.1972 29.35046,11.1972 40.54766,0l23.72839,-23.72839zM57.33333,80.26667c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM86,108.93333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">Hardware Building <br />Materials@PH</h6>
            </div>
          </router-link>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <router-link
            :to="{
              name: 'category_products',
              params: { slug: 'treats-gifts-and-alcohol' },
            }"
          >
            <div class="category-item">
              <h6 class="category-title">SendTreats <br />Home</h6>
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="12"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M59.125,21.5v17.38477l18.72852,-11.24341l5.54297,9.21729l-10.2356,6.14136h18.21411v5.9209c-1.70925,-0.3225 -3.483,-0.5459 -5.375,-0.5459c-5.9985,0 -11.50065,2.0425 -15.97803,5.375h-43.14697v80.625h21.5v10.75h-32.25v-102.125h18.21411l-10.2356,-6.14136l5.54297,-9.21729l18.72852,11.24341v-17.38477zM105.98828,72.22656c0.56437,0.80087 1.00647,1.58268 1.51172,2.36206c0.49988,-0.77938 0.94734,-1.56656 1.51172,-2.36206c4.19787,-5.96625 10.58203,-13.10156 19.98828,-13.10156c8.84187,0 16.125,7.28312 16.125,16.125c0,1.892 -0.39506,3.6765 -1.00781,5.375h11.75781v64.5h-96.75v-64.5h11.75781c-0.60737,-1.6985 -1.00781,-3.483 -1.00781,-5.375c0,-8.84188 7.28312,-16.125 16.125,-16.125c9.40625,0 15.78503,7.13531 19.98828,13.10156zM80.625,75.2605c0,3.04225 2.33275,5.375 5.375,5.375h12.42969c-0.65037,-1.118 -0.52675,-1.18493 -1.34375,-2.36206c-3.35937,-4.78912 -7.72656,-8.39844 -11.08594,-8.39844c-3.04225,0 -5.375,2.34325 -5.375,5.3855zM117.91406,78.27344c-0.817,1.17175 -0.688,1.23894 -1.34375,2.35156h12.42969c3.04225,0 5.375,-2.33275 5.375,-5.375c0,-3.04225 -2.33275,-5.375 -5.375,-5.375c-3.35937,0 -7.72656,3.60931 -11.08594,8.39844zM69.875,134.375h32.25v-43h-32.25zM112.875,134.375h32.25v-43h-32.25z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M48.375,21.5v17.38477l-18.72852,-11.24341l-5.54297,9.21729l10.2356,6.14136h-18.21411v102.125h32.25v-10.75h-21.5v-80.625h43.14697c4.47737,-3.3325 9.97953,-5.375 15.97803,-5.375c1.892,0 3.66575,0.2234 5.375,0.5459v-5.9209h-18.21411l10.2356,-6.14136l-5.54297,-9.21729l-18.72852,11.24341v-17.38477zM86,59.125c-8.84188,0 -16.125,7.28312 -16.125,16.125c0,1.892 0.40044,3.6765 1.00781,5.375h-11.75781v10.75v53.75h96.75v-53.75v-10.75h-11.75781c0.61275,-1.6985 1.00781,-3.483 1.00781,-5.375c0,-8.84188 -7.28313,-16.125 -16.125,-16.125c-9.40625,0 -15.79041,7.13531 -19.98828,13.10156c-0.56437,0.7955 -1.01184,1.58268 -1.51172,2.36206c-0.50525,-0.77938 -0.94734,-1.56118 -1.51172,-2.36206c-4.20325,-5.96625 -10.58203,-13.10156 -19.98828,-13.10156zM86,69.875c3.35938,0 7.72656,3.60931 11.08594,8.39844c0.817,1.17713 0.69338,1.24406 1.34375,2.36206h-12.42969c-3.04225,0 -5.375,-2.33275 -5.375,-5.375c0,-3.04225 2.33275,-5.3855 5.375,-5.3855zM129,69.875c3.04225,0 5.375,2.33275 5.375,5.375c0,3.04225 -2.33275,5.375 -5.375,5.375h-12.42969c0.65575,-1.11263 0.52675,-1.17981 1.34375,-2.35156c3.35938,-4.78912 7.72656,-8.39844 11.08594,-8.39844zM69.875,91.375h32.25v43h-32.25zM112.875,91.375h32.25v43h-32.25z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">TreatsEvents <br />TakeAways@PH</h6>
            </div>
          </router-link>
          <a href="/products/treats-gifts-and-alcohol"> </a>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <a href="/shop">
            <div class="category-item">
              <h6 class="category-title">Supermarket <br />Stores</h6>
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="9"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M120.4,5.73333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,2.08347 -1.16942,3.83358 -2.83308,4.8375l9.39505,15.65469c3.20493,5.34347 4.90469,11.46604 4.90469,17.70391v1.93724h-51.6v-1.93724c0,-6.23213 1.69402,-12.36044 4.90469,-17.70391l9.39505,-15.65469c-1.66365,-1.00392 -2.83308,-2.75403 -2.83308,-4.8375c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM81.37526,25.54245l-0.30234,0.5263c-2.3736,3.95027 -4.06171,8.23271 -5.15104,12.67604l-16.86407,-11.24271c3.4572,-2.81507 7.80432,-4.56875 12.60886,-4.56875c3.5432,0 6.81899,0.99805 9.70859,2.60911zM45.72109,32.38438l28.81224,19.21563h-45.86667l8.46562,-16.93125c1.1954,-2.3908 3.75766,-3.5853 6.21484,-3.2026c0.81906,0.12757 1.63293,0.42516 2.37396,0.91823zM154.8,63.06667c3.1648,0 5.73333,2.56853 5.73333,5.73333v11.46667c0,3.1648 -2.56853,5.73333 -5.73333,5.73333h-0.95183l-8.92474,53.48125c-0.92307,5.52693 -5.70269,9.58542 -11.30989,9.58542h-95.23828c-5.60147,0 -10.38683,-4.05848 -11.3099,-9.58542l-8.91354,-53.48125h-0.95182c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333v-11.46667c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM57.33333,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM80.26667,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM103.2,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M103.2,5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333c0,2.08347 1.16942,3.83358 2.83308,4.8375l-9.39505,15.65469c-3.21067,5.34347 -4.90469,11.47177 -4.90469,17.70391v1.93724h51.6v-1.93724c0,-6.23787 -1.69976,-12.36044 -4.90469,-17.70391l-9.39505,-15.65469c1.66365,-1.00392 2.83308,-2.75403 2.83308,-4.8375c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM71.66667,22.93333c-4.80453,0 -9.15166,1.75368 -12.60886,4.56875l16.86407,11.24271c1.08933,-4.44333 2.77744,-8.72577 5.15104,-12.67604l0.30234,-0.5263c-2.8896,-1.61107 -6.16539,-2.60911 -9.70859,-2.60911zM43.34714,31.46615c-2.45718,-0.3827 -5.01944,0.8118 -6.21484,3.2026l-8.46562,16.93125h45.86667l-28.81224,-19.21563c-0.74103,-0.49307 -1.5549,-0.79066 -2.37396,-0.91823zM17.2,63.06667c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333v11.46667c0,3.1648 2.56853,5.73333 5.73333,5.73333h0.95182l8.91354,53.48125c0.92307,5.52693 5.70843,9.58542 11.3099,9.58542h95.23828c5.6072,0 10.38683,-4.05848 11.30989,-9.58542l8.92474,-53.48125h0.95183c3.1648,0 5.73333,-2.56853 5.73333,-5.73333v-11.46667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM63.06667,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM86,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM108.93333,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">GroceryBakery <br />Greens@PH</h6>
            </div>
          </a>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <a href="/shop">
            <div class="category-item">
              <h6 class="category-title">EasyToComplex Jobs</h6>
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="9"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M120.4,5.73333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,2.08347 -1.16942,3.83358 -2.83308,4.8375l9.39505,15.65469c3.20493,5.34347 4.90469,11.46604 4.90469,17.70391v1.93724h-51.6v-1.93724c0,-6.23213 1.69402,-12.36044 4.90469,-17.70391l9.39505,-15.65469c-1.66365,-1.00392 -2.83308,-2.75403 -2.83308,-4.8375c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM81.37526,25.54245l-0.30234,0.5263c-2.3736,3.95027 -4.06171,8.23271 -5.15104,12.67604l-16.86407,-11.24271c3.4572,-2.81507 7.80432,-4.56875 12.60886,-4.56875c3.5432,0 6.81899,0.99805 9.70859,2.60911zM45.72109,32.38438l28.81224,19.21563h-45.86667l8.46562,-16.93125c1.1954,-2.3908 3.75766,-3.5853 6.21484,-3.2026c0.81906,0.12757 1.63293,0.42516 2.37396,0.91823zM154.8,63.06667c3.1648,0 5.73333,2.56853 5.73333,5.73333v11.46667c0,3.1648 -2.56853,5.73333 -5.73333,5.73333h-0.95183l-8.92474,53.48125c-0.92307,5.52693 -5.70269,9.58542 -11.30989,9.58542h-95.23828c-5.60147,0 -10.38683,-4.05848 -11.3099,-9.58542l-8.91354,-53.48125h-0.95182c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333v-11.46667c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM57.33333,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM80.26667,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM103.2,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M103.2,5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333c0,2.08347 1.16942,3.83358 2.83308,4.8375l-9.39505,15.65469c-3.21067,5.34347 -4.90469,11.47177 -4.90469,17.70391v1.93724h51.6v-1.93724c0,-6.23787 -1.69976,-12.36044 -4.90469,-17.70391l-9.39505,-15.65469c1.66365,-1.00392 2.83308,-2.75403 2.83308,-4.8375c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM71.66667,22.93333c-4.80453,0 -9.15166,1.75368 -12.60886,4.56875l16.86407,11.24271c1.08933,-4.44333 2.77744,-8.72577 5.15104,-12.67604l0.30234,-0.5263c-2.8896,-1.61107 -6.16539,-2.60911 -9.70859,-2.60911zM43.34714,31.46615c-2.45718,-0.3827 -5.01944,0.8118 -6.21484,3.2026l-8.46562,16.93125h45.86667l-28.81224,-19.21563c-0.74103,-0.49307 -1.5549,-0.79066 -2.37396,-0.91823zM17.2,63.06667c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333v11.46667c0,3.1648 2.56853,5.73333 5.73333,5.73333h0.95182l8.91354,53.48125c0.92307,5.52693 5.70843,9.58542 11.3099,9.58542h95.23828c5.6072,0 10.38683,-4.05848 11.30989,-9.58542l8.92474,-53.48125h0.95183c3.1648,0 5.73333,-2.56853 5.73333,-5.73333v-11.46667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM63.06667,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM86,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM108.93333,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">Runners Specialist@PH</h6>
            </div>
          </a>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <router-link :to="{ name: 'dial_from_263' }">
            <div class="category-item">
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(4.73,4.73) scale(0.945,0.945)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#000000"
                        stroke="#757575"
                        stroke-width="10"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M86,17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-18.67813l-4.13203,6.88h-8.04906l7.26297,-12.07359c0.6192,-1.0664 1.75225,-1.68641 2.95625,-1.68641zM61.92,34.4c5.676,0 10.32,4.644 10.32,10.32v2.02906l4.88453,4.85094h-35.84453c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h37.84v37.84h-20.64c-7.6024,0 -13.76,6.1576 -13.76,13.76v3.44h-3.44c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h3.44v13.76h-20.64c-3.784,0 -6.88,-3.096 -6.88,-6.88v-2.02906l-4.88453,-4.85094h15.20453c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-17.2v-55.04h17.2c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-15.20453l4.88453,-4.85094v-2.02906c0,-5.676 4.644,-10.32 10.32,-10.32zM151.36,103.2c5.676,0 10.32,4.644 10.32,10.32v30.96c0,5.676 -4.644,10.32 -10.32,10.32h-89.44c-5.676,0 -10.32,-4.644 -10.32,-10.32v-30.96c0,-5.676 4.644,-10.32 10.32,-10.32zM58.48,134.16h8.77469c2.408,4.1968 6.91171,6.88 11.86531,6.88c3.956,0 7.67281,-1.71865 10.25281,-4.64265c4.4032,2.1328 10.0757,1.23705 13.6189,-2.23735h21.05656c4.472,4.4032 12.31144,4.4032 16.78344,0h13.96828v-10.32h-96.32z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M65.36,17.2c-1.204,0 -2.33705,0.62001 -2.95625,1.68641l-7.26297,12.07359h8.04906l4.13203,-6.88h18.67813c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44zM27.52,34.4c-5.676,0 -10.32,4.644 -10.32,10.32v2.02906l-4.88453,4.85094h15.20453c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-17.2v55.04h17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-15.20453l4.88453,4.85094v2.02906c0,3.784 3.096,6.88 6.88,6.88h20.64v-13.76h-3.44c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h3.44v-3.44c0,-7.6024 6.1576,-13.76 13.76,-13.76h20.64v-37.84h-37.84c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h35.84453l-4.88453,-4.85094v-2.02906c0,-5.676 -4.644,-10.32 -10.32,-10.32h-0.79281h-8.04906zM61.92,103.2c-5.676,0 -10.32,4.644 -10.32,10.32v6.88v24.08c0,5.676 4.644,10.32 10.32,10.32h89.44c5.676,0 10.32,-4.644 10.32,-10.32v-30.96c0,-5.676 -4.644,-10.32 -10.32,-10.32zM58.48,123.84h96.32v10.32h-13.96828c-4.472,4.4032 -12.31144,4.4032 -16.78344,0h-21.05656c-3.5432,3.4744 -9.2157,4.37015 -13.6189,2.23735c-2.58,2.924 -6.29681,4.64265 -10.25281,4.64265c-4.9536,0 -9.45731,-2.6832 -11.86531,-6.88h-8.77469z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">Recharge DialFrom263PH</h6>
            </div>
          </router-link>
        </div> -->
        <!-- <div class="col-3 col-cat4">
          <a href="/shop/greenwood-pharmacy-online">
            <div class="category-item">
              <div class="category-icon">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  viewBox="0 0 172 172"
                  style="fill: #000000"
                >
                  <g transform="translate(5.676,5.676) scale(0.934,0.934)">
                    <g
                      fill="none"
                      fill-rule="nonzero"
                      stroke="none"
                      stroke-width="none"
                      stroke-linecap="butt"
                      stroke-linejoin="none"
                      stroke-miterlimit="10"
                      stroke-dasharray=""
                      stroke-dashoffset="0"
                      font-family="none"
                      font-weight="none"
                      font-size="none"
                      text-anchor="none"
                      style="mix-blend-mode: normal"
                    >
                      <g
                        fill="#f9a51e"
                        stroke="#757575"
                        stroke-width="12"
                        stroke-linejoin="round"
                      >
                        <path
                          d="M134.73333,22.93333c11.08253,0 20.06667,8.98413 20.06667,20.06667c0,11.08253 -8.98413,20.06667 -20.06667,20.06667h-40.13333c-11.08253,0 -20.06667,-8.98413 -20.06667,-20.06667c0,-11.08253 8.98413,-20.06667 20.06667,-20.06667zM86,43c0,4.74147 3.85853,8.6 8.6,8.6h20.06667v-17.2h-20.06667c-4.74147,0 -8.6,3.85853 -8.6,8.6zM53.08932,81.87917h0.06719l49.84193,21.73516v0.0112c3.53879,1.15344 5.93396,4.45245 5.93489,8.17448c0,4.74965 -3.85035,8.6 -8.6,8.6h8.6l39.34948,-16.38255v0.0112c1.14124,-0.54091 2.3876,-0.82382 3.65052,-0.82864c4.74965,0 8.6,3.85035 8.6,8.6c-0.00543,3.11775 -1.69782,5.98839 -4.42317,7.50261l0.0112,0.0112l-51.52161,28.62187c-0.00048,0.00024 -0.03284,-0.00025 -0.0336,0c-1.28963,0.73611 -2.74789,1.12575 -4.23281,1.13099c-1.28353,-0.00353 -2.54999,-0.29432 -3.70651,-0.85104c-0.00013,0.00001 -0.0224,0 -0.0224,0l-51.96953,-21.23124c-2.88387,-1.18107 -6.11433,-1.13762 -8.96953,0.10078l-24.1987,10.51484v-45.86667l27.99479,-10.2237c2.03621,-0.8192 4.21039,-1.24111 6.40521,-1.24297c2.49603,0.00706 4.96063,0.5573 7.22266,1.6125z"
                        ></path>
                      </g>
                      <path
                        d="M0,172v-172h172v172z"
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                      <g
                        fill="#f9a51e"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      >
                        <path
                          d="M94.6,22.93333c-11.08253,0 -20.06667,8.98413 -20.06667,20.06667c0,11.08253 8.98413,20.06667 20.06667,20.06667h40.13333c11.08253,0 20.06667,-8.98413 20.06667,-20.06667c0,-11.08253 -8.98413,-20.06667 -20.06667,-20.06667zM94.6,34.4h20.06667v17.2h-20.06667c-4.74147,0 -8.6,-3.85853 -8.6,-8.6c0,-4.74147 3.85853,-8.6 8.6,-8.6zM45.86667,80.26667c-2.19482,0.00186 -4.369,0.42377 -6.40521,1.24297l-27.99479,10.2237v45.86667l24.1987,-10.51484c2.8552,-1.2384 6.08567,-1.28185 8.96953,-0.10078l51.96953,21.23124c0,0 0.02227,0.00001 0.0224,0c1.15651,0.55672 2.42298,0.84751 3.70651,0.85104c1.48492,-0.00524 2.94318,-0.39489 4.23281,-1.13099c0.00076,-0.00025 0.03312,0.00024 0.0336,0l51.52161,-28.62187l-0.0112,-0.0112c2.72535,-1.51422 4.41774,-4.38486 4.42317,-7.50261c0,-4.74965 -3.85035,-8.6 -8.6,-8.6c-1.26292,0.00482 -2.50929,0.28774 -3.65052,0.82864v-0.0112l-39.34948,16.38255h-8.6c4.74965,0 8.6,-3.85035 8.6,-8.6c-0.00093,-3.72202 -2.3961,-7.02104 -5.93489,-8.17448v-0.0112l-49.84193,-21.73516h-0.06719c-2.26203,-1.0552 -4.72662,-1.60544 -7.22266,-1.6125z"
                        ></path>
                      </g>
                      <path
                        d=""
                        fill="none"
                        stroke="none"
                        stroke-width="1"
                        stroke-linejoin="miter"
                      ></path>
                    </g>
                  </g>
                </svg>
              </div>
              <h6 class="category-title">Greenwood Pharmacy</h6>
            </div>
          </a>
        </div> -->
      </div>
  
      <div v-if="notHomeStoreFront" class="category-container desktop-categories">
        <a class="category-item navigation__item" href="/shop-categories">
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(3.784,3.784) scale(0.956,0.956)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a31e"
                    stroke="#757575"
                    stroke-width="8"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M54.46667,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,40.13333c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM54.46667,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,86c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM54.46667,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM100.33333,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333zM146.2,131.86667c0,7.91773 -6.4156,14.33333 -14.33333,14.33333c-7.91773,0 -14.33333,-6.4156 -14.33333,-14.33333c0,-7.91773 6.4156,-14.33333 14.33333,-14.33333c7.91773,0 14.33333,6.4156 14.33333,14.33333z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a31e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M40.13333,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,25.8c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM40.13333,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,71.66667c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM40.13333,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM86,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333zM131.86667,117.53333c-7.91773,0 -14.33333,6.4156 -14.33333,14.33333c0,7.91773 6.4156,14.33333 14.33333,14.33333c7.91773,0 14.33333,-6.4156 14.33333,-14.33333c0,-7.91773 -6.4156,-14.33333 -14.33333,-14.33333z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">Categories</h6>
        </a>
        <router-link class="category-item" :to="{ name: 'dial_from_263' }">
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.73,4.73) scale(0.945,0.945)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#000000"
                    stroke="#757575"
                    stroke-width="10"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M86,17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-18.67813l-4.13203,6.88h-8.04906l7.26297,-12.07359c0.6192,-1.0664 1.75225,-1.68641 2.95625,-1.68641zM61.92,34.4c5.676,0 10.32,4.644 10.32,10.32v2.02906l4.88453,4.85094h-35.84453c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h37.84v37.84h-20.64c-7.6024,0 -13.76,6.1576 -13.76,13.76v3.44h-3.44c-1.892,0 -3.44,1.548 -3.44,3.44c0,1.892 1.548,3.44 3.44,3.44h3.44v13.76h-20.64c-3.784,0 -6.88,-3.096 -6.88,-6.88v-2.02906l-4.88453,-4.85094h15.20453c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-17.2v-55.04h17.2c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44h-15.20453l4.88453,-4.85094v-2.02906c0,-5.676 4.644,-10.32 10.32,-10.32zM151.36,103.2c5.676,0 10.32,4.644 10.32,10.32v30.96c0,5.676 -4.644,10.32 -10.32,10.32h-89.44c-5.676,0 -10.32,-4.644 -10.32,-10.32v-30.96c0,-5.676 4.644,-10.32 10.32,-10.32zM58.48,134.16h8.77469c2.408,4.1968 6.91171,6.88 11.86531,6.88c3.956,0 7.67281,-1.71865 10.25281,-4.64265c4.4032,2.1328 10.0757,1.23705 13.6189,-2.23735h21.05656c4.472,4.4032 12.31144,4.4032 16.78344,0h13.96828v-10.32h-96.32z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M65.36,17.2c-1.204,0 -2.33705,0.62001 -2.95625,1.68641l-7.26297,12.07359h8.04906l4.13203,-6.88h18.67813c1.892,0 3.44,-1.548 3.44,-3.44c0,-1.892 -1.548,-3.44 -3.44,-3.44zM27.52,34.4c-5.676,0 -10.32,4.644 -10.32,10.32v2.02906l-4.88453,4.85094h15.20453c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-17.2v55.04h17.2c1.892,0 3.44,1.548 3.44,3.44c0,1.892 -1.548,3.44 -3.44,3.44h-15.20453l4.88453,4.85094v2.02906c0,3.784 3.096,6.88 6.88,6.88h20.64v-13.76h-3.44c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h3.44v-3.44c0,-7.6024 6.1576,-13.76 13.76,-13.76h20.64v-37.84h-37.84c-1.892,0 -3.44,-1.548 -3.44,-3.44c0,-1.892 1.548,-3.44 3.44,-3.44h35.84453l-4.88453,-4.85094v-2.02906c0,-5.676 -4.644,-10.32 -10.32,-10.32h-0.79281h-8.04906zM61.92,103.2c-5.676,0 -10.32,4.644 -10.32,10.32v6.88v24.08c0,5.676 4.644,10.32 10.32,10.32h89.44c5.676,0 10.32,-4.644 10.32,-10.32v-30.96c0,-5.676 -4.644,-10.32 -10.32,-10.32zM58.48,123.84h96.32v10.32h-13.96828c-4.472,4.4032 -12.31144,4.4032 -16.78344,0h-21.05656c-3.5432,3.4744 -9.2157,4.37015 -13.6189,2.23735c-2.58,2.924 -6.29681,4.64265 -10.25281,4.64265c-4.9536,0 -9.45731,-2.6832 -11.86531,-6.88h-8.77469z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">Recharge DialFrom263PH</h6>
        </router-link>
  
        <router-link
          class="category-item"
          :to="{ name: 'shop', params: { slug: 'greenwood-pharmacy-online' } }"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.676,5.676) scale(0.934,0.934)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M134.73333,22.93333c11.08253,0 20.06667,8.98413 20.06667,20.06667c0,11.08253 -8.98413,20.06667 -20.06667,20.06667h-40.13333c-11.08253,0 -20.06667,-8.98413 -20.06667,-20.06667c0,-11.08253 8.98413,-20.06667 20.06667,-20.06667zM86,43c0,4.74147 3.85853,8.6 8.6,8.6h20.06667v-17.2h-20.06667c-4.74147,0 -8.6,3.85853 -8.6,8.6zM53.08932,81.87917h0.06719l49.84193,21.73516v0.0112c3.53879,1.15344 5.93396,4.45245 5.93489,8.17448c0,4.74965 -3.85035,8.6 -8.6,8.6h8.6l39.34948,-16.38255v0.0112c1.14124,-0.54091 2.3876,-0.82382 3.65052,-0.82864c4.74965,0 8.6,3.85035 8.6,8.6c-0.00543,3.11775 -1.69782,5.98839 -4.42317,7.50261l0.0112,0.0112l-51.52161,28.62187c-0.00048,0.00024 -0.03284,-0.00025 -0.0336,0c-1.28963,0.73611 -2.74789,1.12575 -4.23281,1.13099c-1.28353,-0.00353 -2.54999,-0.29432 -3.70651,-0.85104c-0.00013,0.00001 -0.0224,0 -0.0224,0l-51.96953,-21.23124c-2.88387,-1.18107 -6.11433,-1.13762 -8.96953,0.10078l-24.1987,10.51484v-45.86667l27.99479,-10.2237c2.03621,-0.8192 4.21039,-1.24111 6.40521,-1.24297c2.49603,0.00706 4.96063,0.5573 7.22266,1.6125z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M94.6,22.93333c-11.08253,0 -20.06667,8.98413 -20.06667,20.06667c0,11.08253 8.98413,20.06667 20.06667,20.06667h40.13333c11.08253,0 20.06667,-8.98413 20.06667,-20.06667c0,-11.08253 -8.98413,-20.06667 -20.06667,-20.06667zM94.6,34.4h20.06667v17.2h-20.06667c-4.74147,0 -8.6,-3.85853 -8.6,-8.6c0,-4.74147 3.85853,-8.6 8.6,-8.6zM45.86667,80.26667c-2.19482,0.00186 -4.369,0.42377 -6.40521,1.24297l-27.99479,10.2237v45.86667l24.1987,-10.51484c2.8552,-1.2384 6.08567,-1.28185 8.96953,-0.10078l51.96953,21.23124c0,0 0.02227,0.00001 0.0224,0c1.15651,0.55672 2.42298,0.84751 3.70651,0.85104c1.48492,-0.00524 2.94318,-0.39489 4.23281,-1.13099c0.00076,-0.00025 0.03312,0.00024 0.0336,0l51.52161,-28.62187l-0.0112,-0.0112c2.72535,-1.51422 4.41774,-4.38486 4.42317,-7.50261c0,-4.74965 -3.85035,-8.6 -8.6,-8.6c-1.26292,0.00482 -2.50929,0.28774 -3.65052,0.82864v-0.0112l-39.34948,16.38255h-8.6c4.74965,0 8.6,-3.85035 8.6,-8.6c-0.00093,-3.72202 -2.3961,-7.02104 -5.93489,-8.17448v-0.0112l-49.84193,-21.73516h-0.06719c-2.26203,-1.0552 -4.72662,-1.60544 -7.22266,-1.6125z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">Greenwood Pharmacy</h6>
        </router-link>
        <router-link class="category-item" :to="{ name: 'pahukama-stores' }">
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="9"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M120.4,5.73333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,2.08347 -1.16942,3.83358 -2.83308,4.8375l9.39505,15.65469c3.20493,5.34347 4.90469,11.46604 4.90469,17.70391v1.93724h-51.6v-1.93724c0,-6.23213 1.69402,-12.36044 4.90469,-17.70391l9.39505,-15.65469c-1.66365,-1.00392 -2.83308,-2.75403 -2.83308,-4.8375c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM81.37526,25.54245l-0.30234,0.5263c-2.3736,3.95027 -4.06171,8.23271 -5.15104,12.67604l-16.86407,-11.24271c3.4572,-2.81507 7.80432,-4.56875 12.60886,-4.56875c3.5432,0 6.81899,0.99805 9.70859,2.60911zM45.72109,32.38438l28.81224,19.21563h-45.86667l8.46562,-16.93125c1.1954,-2.3908 3.75766,-3.5853 6.21484,-3.2026c0.81906,0.12757 1.63293,0.42516 2.37396,0.91823zM154.8,63.06667c3.1648,0 5.73333,2.56853 5.73333,5.73333v11.46667c0,3.1648 -2.56853,5.73333 -5.73333,5.73333h-0.95183l-8.92474,53.48125c-0.92307,5.52693 -5.70269,9.58542 -11.30989,9.58542h-95.23828c-5.60147,0 -10.38683,-4.05848 -11.3099,-9.58542l-8.91354,-53.48125h-0.95182c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333v-11.46667c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM57.33333,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM80.26667,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333zM103.2,91.73333v34.4c0,3.17053 2.5628,5.73333 5.73333,5.73333c3.17053,0 5.73333,-2.5628 5.73333,-5.73333v-34.4c0,-3.17053 -2.5628,-5.73333 -5.73333,-5.73333c-3.17053,0 -5.73333,2.5628 -5.73333,5.73333z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M103.2,5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333c0,2.08347 1.16942,3.83358 2.83308,4.8375l-9.39505,15.65469c-3.21067,5.34347 -4.90469,11.47177 -4.90469,17.70391v1.93724h51.6v-1.93724c0,-6.23787 -1.69976,-12.36044 -4.90469,-17.70391l-9.39505,-15.65469c1.66365,-1.00392 2.83308,-2.75403 2.83308,-4.8375c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM71.66667,22.93333c-4.80453,0 -9.15166,1.75368 -12.60886,4.56875l16.86407,11.24271c1.08933,-4.44333 2.77744,-8.72577 5.15104,-12.67604l0.30234,-0.5263c-2.8896,-1.61107 -6.16539,-2.60911 -9.70859,-2.60911zM43.34714,31.46615c-2.45718,-0.3827 -5.01944,0.8118 -6.21484,3.2026l-8.46562,16.93125h45.86667l-28.81224,-19.21563c-0.74103,-0.49307 -1.5549,-0.79066 -2.37396,-0.91823zM17.2,63.06667c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333v11.46667c0,3.1648 2.56853,5.73333 5.73333,5.73333h0.95182l8.91354,53.48125c0.92307,5.52693 5.70843,9.58542 11.3099,9.58542h95.23828c5.6072,0 10.38683,-4.05848 11.30989,-9.58542l8.92474,-53.48125h0.95183c3.1648,0 5.73333,-2.56853 5.73333,-5.73333v-11.46667c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333zM63.06667,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM86,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333zM108.93333,86c3.17053,0 5.73333,2.5628 5.73333,5.73333v34.4c0,3.17053 -2.5628,5.73333 -5.73333,5.73333c-3.17053,0 -5.73333,-2.5628 -5.73333,-5.73333v-34.4c0,-3.17053 2.5628,-5.73333 5.73333,-5.73333z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">SuperMarketPH</h6>
        </router-link>
  
        <router-link
          class="category-item"
          :to="{
            name: 'category_products',
            params: { slug: 'treats-gifts-and-alcohol' },
          }"
        >
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M59.125,21.5v17.38477l18.72852,-11.24341l5.54297,9.21729l-10.2356,6.14136h18.21411v5.9209c-1.70925,-0.3225 -3.483,-0.5459 -5.375,-0.5459c-5.9985,0 -11.50065,2.0425 -15.97803,5.375h-43.14697v80.625h21.5v10.75h-32.25v-102.125h18.21411l-10.2356,-6.14136l5.54297,-9.21729l18.72852,11.24341v-17.38477zM105.98828,72.22656c0.56437,0.80087 1.00647,1.58268 1.51172,2.36206c0.49988,-0.77938 0.94734,-1.56656 1.51172,-2.36206c4.19787,-5.96625 10.58203,-13.10156 19.98828,-13.10156c8.84187,0 16.125,7.28312 16.125,16.125c0,1.892 -0.39506,3.6765 -1.00781,5.375h11.75781v64.5h-96.75v-64.5h11.75781c-0.60737,-1.6985 -1.00781,-3.483 -1.00781,-5.375c0,-8.84188 7.28312,-16.125 16.125,-16.125c9.40625,0 15.78503,7.13531 19.98828,13.10156zM80.625,75.2605c0,3.04225 2.33275,5.375 5.375,5.375h12.42969c-0.65037,-1.118 -0.52675,-1.18493 -1.34375,-2.36206c-3.35937,-4.78912 -7.72656,-8.39844 -11.08594,-8.39844c-3.04225,0 -5.375,2.34325 -5.375,5.3855zM117.91406,78.27344c-0.817,1.17175 -0.688,1.23894 -1.34375,2.35156h12.42969c3.04225,0 5.375,-2.33275 5.375,-5.375c0,-3.04225 -2.33275,-5.375 -5.375,-5.375c-3.35937,0 -7.72656,3.60931 -11.08594,8.39844zM69.875,134.375h32.25v-43h-32.25zM112.875,134.375h32.25v-43h-32.25z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M48.375,21.5v17.38477l-18.72852,-11.24341l-5.54297,9.21729l10.2356,6.14136h-18.21411v102.125h32.25v-10.75h-21.5v-80.625h43.14697c4.47737,-3.3325 9.97953,-5.375 15.97803,-5.375c1.892,0 3.66575,0.2234 5.375,0.5459v-5.9209h-18.21411l10.2356,-6.14136l-5.54297,-9.21729l-18.72852,11.24341v-17.38477zM86,59.125c-8.84188,0 -16.125,7.28312 -16.125,16.125c0,1.892 0.40044,3.6765 1.00781,5.375h-11.75781v10.75v53.75h96.75v-53.75v-10.75h-11.75781c0.61275,-1.6985 1.00781,-3.483 1.00781,-5.375c0,-8.84188 -7.28313,-16.125 -16.125,-16.125c-9.40625,0 -15.79041,7.13531 -19.98828,13.10156c-0.56437,0.7955 -1.01184,1.58268 -1.51172,2.36206c-0.50525,-0.77938 -0.94734,-1.56118 -1.51172,-2.36206c-4.20325,-5.96625 -10.58203,-13.10156 -19.98828,-13.10156zM86,69.875c3.35938,0 7.72656,3.60931 11.08594,8.39844c0.817,1.17713 0.69338,1.24406 1.34375,2.36206h-12.42969c-3.04225,0 -5.375,-2.33275 -5.375,-5.375c0,-3.04225 2.33275,-5.3855 5.375,-5.3855zM129,69.875c3.04225,0 5.375,2.33275 5.375,5.375c0,3.04225 -2.33275,5.375 -5.375,5.375h-12.42969c0.65575,-1.11263 0.52675,-1.17981 1.34375,-2.35156c3.35938,-4.78912 7.72656,-8.39844 11.08594,-8.39844zM69.875,91.375h32.25v43h-32.25zM112.875,91.375h32.25v43h-32.25z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">Treats</h6>
        </router-link>
  
        <router-link class="category-item" :to="{ name: 'simple_pay_direct' }">
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(4.214,4.214) scale(0.951,0.951)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="9"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M120.0042,20.64068c0.92166,2.17779 0.4118,4.69802 -1.28389,6.3463l-24.61302,24.61302l26.29271,26.29271l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667c0.92166,2.17779 0.4118,4.69801 -1.28389,6.3463l-24.61302,24.61302l18.87969,18.87969c1.49778,1.43802 2.10113,3.5734 1.57735,5.5826c-0.52378,2.0092 -2.09284,3.57826 -4.10204,4.10204c-2.0092,0.52378 -4.14458,-0.07957 -5.5826,-1.57735l-22.09349,-22.09349c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-32.74271,-32.74271c-0.66065,-0.43243 -1.22486,-0.99665 -1.65729,-1.65729l-22.11589,-22.11589c-1.6719,-1.62719 -2.19121,-4.10513 -1.31331,-6.26668c0.8779,-2.16155 2.97794,-3.57567 5.31096,-3.57628c1.54801,0.00009 3.03022,0.62609 4.10964,1.73568l18.87969,18.87969l24.61302,-24.61302c1.03843,-1.06877 2.45215,-1.69129 3.94167,-1.73568c2.36376,-0.06971 4.52786,1.31888 5.44951,3.49667zM127.73464,116.93984l-23.72839,23.72839c-11.1972,11.1972 -29.35046,11.1972 -40.54766,0l-1.16458,-1.16458l-1.63489,1.6237c-5.46387,5.46387 -14.31165,5.46387 -19.77552,0l-0.95182,-0.95182l-12.94479,12.94479c-1.43802,1.49776 -3.57339,2.1011 -5.58258,1.57732c-2.00919,-0.52378 -3.57824,-2.09283 -4.10202,-4.10202c-0.52378,-2.00919 0.07955,-4.14456 1.57731,-5.58258l12.94479,-12.94479l-0.94062,-0.94062c-5.46387,-5.46387 -5.46387,-14.32285 0,-19.78672l1.6237,-1.6237l-1.17578,-1.17578c-11.1972,-11.1972 -11.1972,-29.35046 0,-40.54766l23.72838,-23.72839zM51.6,86c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333zM80.26667,114.66667c0,3.1648 2.56853,5.73333 5.73333,5.73333c3.1648,0 5.73333,-2.56853 5.73333,-5.73333c0,-3.1648 -2.56853,-5.73333 -5.73333,-5.73333c-3.1648,0 -5.73333,2.56853 -5.73333,5.73333z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M114.55469,17.14401c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-18.87969,-18.87969c-1.07942,-1.10959 -2.56163,-1.73559 -4.10964,-1.73568c-2.33303,0.00061 -4.43306,1.41473 -5.31096,3.57628c-0.8779,2.16155 -0.3586,4.6395 1.31331,6.26668l22.11589,22.11589c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l32.74271,32.74271c0.43243,0.66065 0.99664,1.22486 1.65729,1.65729l22.09349,22.09349c1.43802,1.49778 3.5734,2.10113 5.5826,1.57735c2.0092,-0.52378 3.57826,-2.09284 4.10204,-4.10204c0.52378,-2.0092 -0.07957,-4.14458 -1.57735,-5.5826l-18.87969,-18.87969l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08575,-3.56638 -5.44951,-3.49667c-1.48951,0.04438 -2.90324,0.6669 -3.94167,1.73568l-24.61302,24.61302l-26.29271,-26.29271l24.61302,-24.61302c1.69569,-1.64828 2.20555,-4.16851 1.28389,-6.3463c-0.92166,-2.17779 -3.08576,-3.56638 -5.44951,-3.49667zM55.06016,44.26536l-23.72838,23.72839c-11.1972,11.1972 -11.1972,29.35046 0,40.54766l1.17578,1.17578l-1.6237,1.6237c-5.46387,5.46387 -5.46387,14.32285 0,19.78672l0.94062,0.94062l-12.94479,12.94479c-1.49776,1.43802 -2.1011,3.57339 -1.57731,5.58258c0.52378,2.00919 2.09283,3.57824 4.10202,4.10202c2.00919,0.52378 4.14456,-0.07955 5.58258,-1.57732l12.94479,-12.94479l0.95182,0.95182c5.46387,5.46387 14.31165,5.46387 19.77552,0l1.63489,-1.6237l1.16458,1.16458c11.1972,11.1972 29.35046,11.1972 40.54766,0l23.72839,-23.72839zM57.33333,80.26667c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333zM86,108.93333c3.1648,0 5.73333,2.56853 5.73333,5.73333c0,3.1648 -2.56853,5.73333 -5.73333,5.73333c-3.1648,0 -5.73333,-2.56853 -5.73333,-5.73333c0,-3.1648 2.56853,-5.73333 5.73333,-5.73333z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">PayDirectPh</h6>
        </router-link>
  
        <router-link class="category-item" :to="{ name: 'listings' }">
          <div class="category-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 172 172"
              style="fill: #000000"
            >
              <g transform="translate(5.59,5.59) scale(0.935,0.935)">
                <g
                  fill="none"
                  fill-rule="nonzero"
                  stroke="none"
                  stroke-width="none"
                  stroke-linecap="butt"
                  stroke-linejoin="none"
                  stroke-miterlimit="10"
                  stroke-dasharray=""
                  stroke-dashoffset="0"
                  font-family="none"
                  font-weight="none"
                  font-size="none"
                  text-anchor="none"
                  style="mix-blend-mode: normal"
                >
                  <g
                    fill="#f9a51e"
                    stroke="#757575"
                    stroke-width="12"
                    stroke-linejoin="round"
                  >
                    <path
                      d="M165.12,48.16c0,22.76313 -18.51687,41.28 -41.28,41.28c-5.09281,0 -10.06469,-0.92719 -14.82156,-2.76812l-73.54344,73.54344c-3.26531,3.26531 -7.55187,4.89125 -11.85187,4.89125c-4.28656,0 -8.57313,-1.62594 -11.83844,-4.89125c-3.15781,-3.17125 -4.90469,-7.36375 -4.90469,-11.83844c0,-4.48813 1.74688,-8.69406 4.90469,-11.85188l73.55688,-73.54344c-1.85438,-4.75688 -2.78156,-9.72875 -2.78156,-14.82156c0,-22.76312 18.51688,-41.28 41.28,-41.28c4.36719,0 8.7075,0.69875 12.92688,2.08281l5.29437,1.74687l-27.93656,27.95l5.4825,13.73313l13.71969,5.4825l27.95,-27.93656l1.74687,5.29438c1.3975,4.21937 2.09625,8.55969 2.09625,12.92687z"
                    ></path>
                  </g>
                  <path
                    d="M0,172v-172h172v172z"
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                  <g
                    fill="#f9a51e"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  >
                    <path
                      d="M163.02375,35.23313l-1.74687,-5.29438l-27.95,27.93656l-13.71969,-5.4825l-5.4825,-13.73313l27.93656,-27.95l-5.29437,-1.74687c-4.21938,-1.38406 -8.55969,-2.08281 -12.92688,-2.08281c-22.76312,0 -41.28,18.51688 -41.28,41.28c0,5.09281 0.92719,10.06469 2.78156,14.82156l-73.55688,73.54344c-3.15781,3.15781 -4.90469,7.36375 -4.90469,11.85188c0,4.47469 1.74688,8.66719 4.90469,11.83844c3.26531,3.26531 7.55188,4.89125 11.83844,4.89125c4.3,0 8.58656,-1.62594 11.85187,-4.89125l73.54344,-73.54344c4.75688,1.84094 9.72875,2.76812 14.82156,2.76812c22.76313,0 41.28,-18.51687 41.28,-41.28c0,-4.36719 -0.69875,-8.7075 -2.09625,-12.92687z"
                    ></path>
                  </g>
                  <path
                    d=""
                    fill="none"
                    stroke="none"
                    stroke-width="1"
                    stroke-linejoin="miter"
                  ></path>
                </g>
              </g>
            </svg>
          </div>
          <h6 class="category-title">Classified Listing</h6>
        </router-link>
      </div>
  
      <div style="border: 4px solid black; border-left: 16px; border-right: 16px;">
        <div style="border: 4px solid white; border-left: 8px; border-right: 16px;">
          <div class="mobile-categories row second-row" style="background-color: #f9a51e;" >
    <ul class="menu">
      <li class="menu-item">
        <a class="ls-middle-menu-icon-item" href="/dialfrom263">
          <img src="/img/fon.svg" alt="Buy Voucher" />
          <p style="color: black; font-weight: bold;">Call&Talk</p>
        </a>
      </li>
      <li class="menu-item">
        <a class="ls-middle-menu-icon-item" href="/listings/category/pahukama-bnb">
          <img src="/img/home.svg" alt="Topup Smart" />
          <p style="color: black; font-weight: bold;">pHbnbs</p>
        </a>
      </li>
      <li class="menu-item">
        <a class="ls-middle-menu-icon-item" href="/simple_pay_direct">
        <img src="/img/dollar.svg" alt="E-Runner" />
        <p style="color: black; font-weight: bold;">PayBills</p>
      </a>
      </li>
      <li class="menu-item">
        <a class="ls-middle-menu-icon-item" href="/request-quotation">
        <img src="/img/request.svg" alt="Send Prescription" />
        <p style="color: black; font-weight: bold;">ReqQuote</p>
      </a>
      </li>
      <li class="menu-item">
        <a class="ls-middle-menu-icon-item" href="/specialists-jobs-tasks">
      <img src="/img/skills-icon.svg" alt="Listings" />
      <p style="color: black; font-weight: bold;">SkilledTaskers</p>
      </a>
      </li>
    </ul>
  </div>
        </div>
     
      </div>
  
      <!-- <div style="display: flex" v-if="isHome">
        <span
          style="padding: 5px; background-color: black; width: 20vw"
          v-for="product1 in shopThreeProducts"
        >
          <router-link :to="{ name: 'Product', params: { slug: product1.slug } }">
            <img
              style="width: 100%; aspect-ratio: 1; object-fit: cover"
              v-lazy="product1.images[0].path"
            />
          </router-link>
        </span>
      </div> -->
  
      <div>
        <!-- Menu Chips-->
        <!-- <div
          class="row mt-2 mb-2 ls-menu-chips ls-center"
          style="margin-left: 0px; margin-right: 0px"
          v-if="isHome"
        >
          <div class="col-xl-2 col-lg-2 col-12"></div>
          <div class="col-xl-2 col-lg-2 col-3">
            <div class="desktop-listing-cats ls-vertical-center">
              <router-link
                :to="{
                  name: 'dial_from_263',
                }"
              >
                Recharge<br />DialFrom263
              </router-link>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-3">
            <div class="desktop-listing-cats ls-vertical-center">
              <router-link
                :to="{
                  name: 'shop',
                  params: { slug: 'greenwood-pharmacy-online' },
                }"
              >
                Greenwood<br />Pharmacy
              </router-link>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-3">
            <div class="desktop-listing-cats ls-vertical-center">
              <router-link
                style="white-space: nowrap"
                :to="{ name: 'pahukama-stores' }"
              >
                Treats &<br />
                TakeAways
              </router-link>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-3">
            <div id="" class="desktop-listing-cats ls-vertical-center">
              <router-link
                style="white-space: nowrap"
                :to="{
                  name: 'home',
                }"
              >
                Colcom &<br />AMP Meats
              </router-link>
            </div>
          </div>
          <div class="col-xl-2 col-lg-2 col-12"></div>
        </div> -->
      </div>
    </div>
  </template>
  
  <script>
  // export default {
  //   name: "Categories",
  // };
  </script>
  <script>
  import HeaderSearch from "../HeaderSearch";
  import HeaderSearch2 from "../HeaderSearch2";
  import axios from "axios";
  import CartMini from "@/components/CartMini.vue";
  import { mapState } from "vuex";
  export default {
    name: "Categories",
    data() {
      return {
        currentPlace: {},
        loading: true,
        notHomeStoreFront: true,
        isHome: true,
        shops: [],
        shopOne: null,
        shopOneProducts: null,
        shopTwo: null,
        shopTwoProducts: null,
        shopThree: null,
        shopThreeProducts: [],
        shopFour: null,
        shopFourProducts: [],
        count: 0,
      };
    },
    components: {
      HeaderSearch,
      HeaderSearch2,
      CartMini,
    },
    mounted() {
      this.notHomeStoreFront = !(this.$route.path == "/home-store-front");
      this.isHome = this.$route.path == "/";
      this.getShops();
      // this.notHomeStoreFront = !(this.$route.path == "/");
    },
    watch: {
      $route(to, from) {
        this.notHomeStoreFront = !(this.$route.path == "/home-store-front");
        this.isHome = this.$route.path == "/";
        // this.notHomeStoreFront = !(this.$route.path == "/");
      },
    },
    computed: {
      ...mapState({
        auth: (state) => state.auth,
        shop: (state) => state.shop,
      }),
    },
    methods: {
      getShops() {
        this.shops.length = 0;
        this.$loading(true);
  
        axios
          .get("shops/list")
          .then((response) => {
            this.shops = response.data;
            let limit = 7;
            if (this.shops.length > 0) {
              this.shopOne = this.shops[0];
              if (this.shopOne) {
                this.shopOneProducts = this.getShopProducts(this.shopOne, limit);
              }
            }
  
            if (this.shops.length > 1) {
              this.shopTwo = this.shops[1];
              if (this.shopTwo) {
                this.shopTwoProducts = this.getShopProducts(this.shopTwo, limit);
              }
            }
            if (this.shops.length > 2) {
              this.shopThree = this.shops[2];
              if (this.shopThree) {
                this.shopThreeProducts = this.getShopProducts(
                  this.shopThree,
                  limit
                );
              }
            }
            if (this.shops.length > 3) {
              this.shopFour = this.shops[2];
              if (this.shopFour) {
                this.shopFourProducts = this.getShopProducts(
                  this.shopFour,
                  limit
                );
              }
            }
            this.$loading(false);
          })
          .catch(function (error) {
            // handle error
            console.log(error);
            this.$loading(false);
          });
      },
  
      getShopProducts(shop, limit = 6) {
        let shopProducts = [];
        axios
          .get(`products?shop=${shop.id}&limit=${limit}`)
          .then((response) => {
            response.data.data.filter((product, i) => {
              if (i < limit) {
                shopProducts.push(product);
              }
            });
          })
          .catch(function (error) {
            // handle error
            console.log(error);
          });
        return shopProducts;
      },
      setPlace(place) {
        this.currentPlace = place;
      },
      logout() {
        this.$store.dispatch("auth/logout");
        this.$router.push({ name: "home" });
      },
    },
  };
  </script>
  
  <style scoped>
  /* @import url('/css/common12d2.css'); */
  .category-container {
    display: flex;
  }
  
  .mobile-categories {
    display: none;
  }
  @media screen and (max-width: 575px) {
    .mobile-categories {
      display: inline-flex;
      overflow-x: scroll;
      width: 100%;
    }
  
    .desktop-categories {
      display: none;
    }
  }
  @media screen and (max-width: 575px) {
    .category-container {
      /* overflow-x: scroll;
      touch-action: pan-x; */
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
  
  .category-item {
    flex: 1;
    padding: 0.5rem 0;
    border: 1px solid rgba(38, 38, 38, 0.132);
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    transition: all 0.3s ease-in;
  }
  
  @media screen and (max-width: 575px) {
    .category-item {
      min-width: 12rem;
      padding: 10px 0;
    }
  }
  
  .category-item:hover {
    cursor: pointer;
  }
  
  .category-item:hover > * {
    color: #757575;
    -webkit-text-stroke: 0.5px #f79b07;
  }
  
  .category-title {
    margin: 0;
    font-size: 0.82em;
    color: #747474;
    text-shadow: 1px 4px 6px #def, 0 0 0 #000, 1px 4px 6px #def;
    text-align: center;
  }
  
  .category-icon {
    width: 50px;
    height: 54px;
  }
  
  @media screen and (max-width: 575px) {
    .category-icon {
      width: 30px;
      height: 34px;
    }
    .category-title {
      font-size: 10px;
    }
    .col-cat4 {
      padding: 0;
    }
    .ps-search--mobile {
      padding: 1rem 2rem 2rem 2rem;
    }
  }
  </style>
  